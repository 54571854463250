.search {
  .search-box {
    width: 100%;
    padding: 8px;
    border-width: none;
    border: 1px solid #0000002b;
    text-transform: capitalize;
    border-radius: 4px;
    height: 40px;
      &:focus{
        outline: none;
        border-radius: 6px;
        border: 3px solid #abccf0;
      }
   }
  .option-container{
    background-color: white !important;
    li{
      list-style: none;
    }
  
 }
 .no-options {
  color: white;
  background-color: white;
  padding: 10px;
 }
}

.options_test {
  position: absolute !important;
  background-color: white !important;
  width: 91.3% !important;
  z-index: 111 !important;
  padding-left: 0px;
  overflow: scroll;
  max-height: 200px;
}

.item-option:hover {
  background-color: silver;
}