.modal-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
   .card-box {
       position: relative;
   }
    .overlay-modal{
        position: absolute;
         right: 0%;
         width: 100%;
         height: 100%;
         opacity: 0.4;
         background-color: #000000;
         z-index: 1300;
    }
    .add-new-section-img-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .add-new-img-cropper {

        .add-image-cropper-btn {
            position: absolute;
        }
    }
    .card-box{
        background-color: #eee;
        box-shadow: 0px 0px 0px 2px #F5F5F5;
        padding: 35px ;
        position: relative;
    }

    .card-box-index{
        z-index: 1400;
        // display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;
    }
    .form-group-container{
        .form-content{
            label{
                padding-top: 10px
            }
            input{
                width: 100%;
                border-width: none;
                border: none;
                padding: 10px;
                border-radius: 5px
            }
            textarea{
                width: 100%;
                border-width: none;
                border: none;
                padding: 10px;
                border-radius: 5px
            }
        }
        .add-new-section-btn-container{
            display: flex;
            justify-content: space-between;
            margin-top: 19px;
    
            button{
                border: none;
                padding: 0 20px
            }
            .add-new-section-cancel-btn{
                background-color:#e2e2e2;
                width: 49%;
                height: 38px;
                border-radius: 2px;
                opacity: 0.8;
                color: black;
                font-weight: 600;
                font-size: larger
            }
            .add-new-section-done-btn{
                background-color:#007BFF;
                width: 49%;
                height: 38px;
                border-radius: 2px;
                opacity: 0.8;
                color: #fff;
                font-weight: 600;
                font-size: larger
            }

            .disable-btn {
                background-color: darkgrey;
                cursor:not-allowed !important;
            } 
        }
    }
    .add-new-section-img-container{
        cursor: pointer;
        position: relative;
        width: 320;
        height: 200;
        background-color: "#CCCCCC";
        border-radius: "10px";

        img{
          width:100%;
          height: 100%;
          border-radius: 14px;
          background-color: #CECECE;
        }
    }
    .add-new-section-icon{
        display: flex;
        padding: 6px 10px;
        border: 2px solid white;
        border-radius: 8px;
        color: rgb(255, 255, 255);
        background-color: rgba(0, 0, 0, 0.6);
        cursor: pointer;
        width: 99px;
        position: absolute;
        top: 138px;
        left: 105px;

        span{
            padding: 2px 5px;
        }
    }
    
}
// .MuiCollapse-container {
//     background-color: #f4f4f4 !important;
// }