.section-container{
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    height: 200;
    width: 32%;
    position: relative;
    .section-content{
        position: absolute;
        top: 10;
        left: 10;
        background-color: rgba(0,0,0,0.6);
        padding: 4px 8px;
        border-radius: 4px;
        border: 1px solid white;
        cursor: pointer;
        margin-left: 10px;
        margin-top: 10px;
        h7{
            color: #fff;
            font-size: 11;
            font-weight: bold;
        }
        img{
            height: 100%;
            width: 100%;
            border-radius: 10px;
        }
    }
    .section-icons{
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;
        color: white;
    }
    .section-iconc-edit{
        padding: 4px 8px;
        border-radius: 10px;
        border: 1px solid white;
        cursor: pointer;
        background-color: rgba(0,0,0,0.6);
        margin-right: 10px;
    }
    .section-icons-delete{
        padding: 4px 8px;
        border-radius: 10px;
        border: 1px solid white;
        cursor: pointer;
        background-color: rgba(0,0,0,0.6);
    }
    .proirty{
        position: absolute;
        top: 4%;
        right: 4%;
        font-size: 16px;
        background-color: rgba(0,0,0,0.6);
        color: white;
        border: 1px solid white
    }
}