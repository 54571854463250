.contact-info-container{
    padding: 35px 25px;
   .contact-info-heading{
    text-align: left
   }
   .contact-info-close-icon{
    position: absolute;
    right: 47px;
    font-size: 15px;
   }
   .contact-info-details{
       display: flex;
       flex-direction: column;
       justify-content: center; 
    }
   .contact-info-content{
       background-color: #fff;
       border-radius: 10px;
       margin-top: 10px;
       label{
        margin-left: 10px;
        color: #BFBFBF
       }
       p{
        margin-left: 10px;
       }
       .content{
           margin-top: 10px;
       }
   }
}