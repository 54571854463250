.css-1pahdxg-control {
    width: 150px;
    height: 10px !important;
}

.css-g1d714-ValueContainer {
    width: 110px;
}

.MuiCollapse-wrapper {
    padding: 0px !important;
    // height: 0px !important;
    // display:none !important
}