.guest-modal{
    position: absolute;
    right: 0;
    top: 10px;
    width: 600px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 42px 40px;
    text-align: left;
    // @media (max-width:767px){
    //     right: 5%;
    //     width: 120%;
    //     overflow: auto;
    // }
    .corp-row{
        // h6{
        //  font-weight: 900 !important;
        //  font-size: 12px !important;
        //  margin-bottom: .5rem !important;
        //  text-align: left !important;
        // }
        p {
            // @media (max-width:767px){
            //     font-size: 14px;
            // }
        }
    }

    .corp-content{
        // padding: 0px!important;
        // margin: 0px!important;
        // border-right: 1px solid #6666;
        // @media (max-width:767px){
        //     border-right: transparent;
        // }
    }
    // .adults{
    //     font-weight: 900 !important;
    //     font-size: 12px !important;
    //     margin-bottom: .5rem !important;
    //     text-align: left !important; 
    // }
    .corp-content-single-row{
        padding-bottom: 15px;
        // @media (max-width:767px){
        //     flex-wrap: nowrap;
        //     .corp-icon {
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         img {
        //             width: 25px !important;
        //             height: 25px !important;
        //         }
        //     }
        // }
        p {
            font-size: 13px !important;
        }
    }

    .corp-icon{
        img{
          cursor: pointer;
          width: 33%;
        //   @media (max-width: 767px){
        //     width: 22%;
        //   }
        }
        span{
            padding: 15px 10px;
            font-size: 23px;
            font-weight: 500;
        }
    }
    .additional-note{
        margin-top: 20px;
        color: 666666;
        font-size: 8px;
        color: #666666;
        text-align: center
    }
}

.prepareGift-holder .guest-modal {
    position: relative;
    left: 0;
    right: 35%;
    top: 0%;
    width: 150%;
}

.title{
    font-size: 10px !important;
}
// .sub-title{
//     // font-size: ;
// }