.ButtonState{display:none}
.Button{
    padding:8px; 
    margin-left:25px;
    width: 15%; 
    border:2px solid rgb(213, 215, 219); 
    border-radius: 10px;
    text-align: center;
    cursor:pointer;
}
.ButtonState:checked + .Button{
    background:#fff;
    border-color: rgb(33, 150, 243);
}
.schedulePopup{
    display: none;
}

.weekDays-selector input {
    display: none!important;
  }
  
  
  .weekDays-selector input[type=checkbox] + label {
    display: block;
    border-radius: 6px;
    padding-left: 8px;
    height: 40px;
    width: 200px;
    margin-bottom: 3px;
    line-height: 40px;
    text-align: left;
    cursor: pointer;
  }
  
  .weekDays-selector input[type=checkbox]:checked + label {
    background: rgb(33, 150, 243);
    color: #ffffff;
    font-weight: bold;
}