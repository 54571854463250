.section-container{
    cursor: pointer;
    position: relative;
    width: 32%;
    height: 218px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #CCCCCC;
    border-radius: 10px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 14px;
      background-color: #CECECE;
    }
    h6{
      color: white;
      position: absolute;
      bottom: 90px;
      left: 100px;
      border: 1px solid white;
      padding: 6px;
      border-radius: 6px;
      background-color: #525252 
    }
};