.calander-container{
    background-color: #fff;
    padding: 15px;
    position: absolute;
    top:30%;
    margin-left: 21px;
    padding: 5px 56px;
    margin-top: 40px;
   .rmdp-shadow {
      box-shadow: none!important;
    //   padding: 37px;
    }
    button{
        width: 72px;
        background: #2196F3;
        color: white;
        border-width: inherit;
        border-radius: 4px;
    }
}