.date-filter {
  display: flex;
  align-items: flex-end;
  .date-filter-from{
    margin: 0px 10px 0px 0px;
  }
  .date-filter-to{
    margin: 0px 10px 0px 10px;
  }
  .date-filter-icon{
    font-size: 18px;
    cursor: pointer;
    margin-right: 5px;
  }
}