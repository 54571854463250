h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
select,
option,
input,
textarea,
button,
a,
::placeholder,
body {
  font-family: "DM Sans", sans-serif !important;
}

.MuiToolbar-root {
  background-color: #e5e5e5;
}

.page-container {
  background: #e5e5e5;
}

.sub-menu-holder {
  padding: 8px;
  .active {
    .MuiList-root {
      background: #e3f2fd;
      border: 3px solid #ffffff;
      border-radius: 8px;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: #2196f3;
      }
    }
  }
}
.title-holder {
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 36px;
}
.btn-save {
  background: #2196f3;
  border-radius: 2px;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  width: 107px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.map-dailog {
  .MuiDialog-paperWidthSm {
    width: 800px !important;
  }
}

.pac-container {
  z-index: 1350 !important;
}
.autocomplete-input {
  position: absolute;
  top: -60px;
  /* left: 50%; */
  width: 400px;
  margin-top: 3px;
  margin-bottom: 10px;
}

.cursor-pointer {
  cursor: pointer;
}
.overflow--scroll {
  height: 100vh;
  overflow-y: scroll;
  clear: both;
}

.cancelled .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.booking .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.confirmed .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.ant-select-selection-placeholder,
.ant-select-arrow {
  color: black;
}
.message-limit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

header {
  z-index: -1 !important;
}
.toggle {
  background-color: white;
  width: 60%;
  margin-top: 10px;
}
.disablePreviousDate {
  pointer-events: none;
}
.title-manageFeatured{
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;

  
}
.sectionName-manageFeatured{
  
  position: static;
width: 669px;
height: 16px;
left: calc(50% - 669px/2);
top: calc(50% - 16px/2 + 25px);

font-family: DM Sans;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
/* identical to box height */

display: flex;
align-items: center;

color: #000000;

opacity: 0.7;

/* Inside auto layout */

flex: none;
order: 3;
align-self: stretch;
flex-grow: 0;
margin: 4px 0px;
}
.download-csv{
  // background-color: white;
  font-size: 14px;
  line-height: 36px;
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
  color: white;
}
.manageFeatured_box:active{
border: 2px solid black;
}

.manageFeatured_city{
  position: static;
width: 669px;
height: 16px;
left: calc(50% - 669px/2);
top: calc(50% - 16px/2 + 5px);
font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
/* identical to box height */

display: flex;
align-items: center;

color: #797979;


/* Inside auto layout */

flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;
margin: 4px 0px;
flex-wrap: wrap;
}

.manageFeatured_box_subTitle{
  position: static;
width: 518px;
height: 24px;
left: 0px;
top: 52px;

font-family: DM Sans;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 133% */

display: flex;
align-items: center;

color: #000000;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 10px 0px;
}
.manageFeatured_box_title{
  position: static;
width: 550px;
height: 42px;
left: -2.5px;
top: 0px;

/* Page title */

font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 42px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.15px;

color: #000000;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
}
.manageFeatured_box_closeIcon{
  position: absolute;
left: 16.67%;
right: 16.67%;
top: 16.67%;
bottom: 16.67%;

border: 2px solid #000000;
}
.manageFeatured_box{
  display: flex;
flex-direction: column;
// align-items: flex-start;
padding: 18px 20px 16px;
margin-bottom: 10px;
// position: absolute;
width: 709px;
height: 98px;
left: 0px;
top: -2px;

background: #FAFAFA;
border-radius: 4px;
}
.manageFeatured_box_save{
  position: static;
width: 107px;
height: 32px;
right: 0px;
top: 744px;
left: 602px;
/* Accent */

background: #2196F3;
border-radius: 2px;

/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;
margin: 25px 0px;
}
.manageFeatured_box_save_content{
  position: absolute;
width: 107px;
height: 16px;
left: calc(50% - 107px/2);
top: calc(50% - 16px/2);

font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 16px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;

/* Light */

color: #FFFFFF;
}
.subscribtion-card{
  background-color: #FFFFFF;
  padding: 50px;
  width: 300px;
  h1{
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
  }
  p{
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
  }
}
.copy-button{
  a{
  background: #FFFFFF;
  border: 1px solid #2196F3;
  border-radius: 4px;
  align-items: center;
  padding: 7px 25px;
  margin: 0 0 0 5px;
    color: #2196F3 !important;
  }
  .disabled{
    background: #FFFFFF;
    opacity: 0.5;
    cursor: not-allowed;
  }
}


.blurry-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0,0,0,0.5);
}
.toggle-button{
    background: #fbfcfd;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 4px;
}
.activity-dropdown{
  background-color: #ffffff;
  border: 1px solid #0000002b;
  border-radius: 4px;
  .MuiButton-root:hover {
    text-decoration: none;
    background-color: #fff;
  }
  .MuiButton-label {
    text-align: left;
    display: block;
    text-transform: capitalize;
    color: #666;
    padding-left: 10px;
}
}
.common-error{
  p{
    color: red;
    margin-bottom: 0;
    margin-top: 5px;
  }
}
.common-images-box{
  
  position: relative;
  width : 320px;
  height: 200px;
  cursor: pointer;

  img{
    width: 100% !important;
    height: 100% !important;
    border-radius: 14px;
    background-color: "#CECECE"
  }
  h6{
      color: #fff;
      position: absolute;
      bottom: 20px;
      left: 80px;
      border: 1px solid #fff;
      padding: 6px;
      border-radius: 6px;
      background-color: #525252;
      span{
        padding: 0 5px;
      }
  }
  
}
.package-price{
  flex: 0.5; 
  position: relative;
  margin-top: 0px ;
  h6{
      position: absolute;
      left: 30px;
      top: 53px;
      font-weight: 400;
      opacity: 0.4
  }
  .divider{
      position: absolute;
      padding-bottom: 30px;
      height: 17%;
      width: 1px;
      left: 73px;
      background-color: rgb(202, 194, 194)
  }
  input{
    padding-left: 75px;
  }
}
.featured-amenities{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .box{
    background-color: #fff;
    border-radius: 5px;
    margin-top: 10px;
    flex: 0.5;
    margin: 10px 2px 0px;
    .MuiFormControl-root{
      display: flex;
      .MuiFormControlLabel-root{
        justify-content: space-between;
        align-items: center;
        .MuiFormControlLabel-label{
          font-size: 0.9rem;
        }
      }
    }
    
  }
}
.min-max-input{
  display: flex;
}

.itinerary-schedule-box{
  padding: 20px 40px;
  background: #fff;
  .add{
    background-color: #EDEDED;
    align-items: center;
    padding: 10px 20px;
    p{
      margin-bottom: 0;
    }
  }
  .add-button{
    a{
      background-color: #2196F3;
      padding: 5px 20px;
      color: #ffffff;
      font-weight: 500;
      border-radius: 3px;
      
    }
    :hover{
      color: #EDEDED !important;
    }
  }
  .dates-card{
    background-color: #fff;
    border: 1px solid #EDEDED;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    
  }
}

.pending-status{
  color: red;
  font-size: 12px;
}
.success-status{
  color: green;
  font-size: 12px;
}
.blured-backround{
  width: 100%;
    margin: 0 auto;
    top: 50%;
    padding: 0 10%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    position: absolute;
    overflow: auto;
    z-index: 99;
    height: 100%;
   background: #eee;
   opacity: 0.3;

    p{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 70%;
    }
}

.loding-spinner{
  position: fixed;
  top: 60%;
  left: 55%;
  z-index: 9999;
  opacity: 0.8;
}
.pe-none{
  pointer-events: none;
}
.ck-editor__editable_inline {
  height: 500px;
  overflow: scroll;
}
.ck-content{
  background-color: #fbfbfb;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  border: 1px solid #cccde1;
}
.main-popular-detinations{


  .popular-destinations{
    h3{ 
      position: absolute;
      top: 35px;
      font-family: 'DM Sans', sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.15px;
  
    }
    .selectLoc{
      position: absolute;
    margin-left: 32%;
    bottom: 18px;
    border: none;
    background: transparent;
    color: #0496ff;
    font-weight: 500;
    font-size: 18px;
    text-decoration: underline;
    @media screen and (min-width: 1300px) and (max-width: 1400px)  {
      margin-left: 35%;
      
    }
    @media screen and (min-width: 1200px) and (max-width: 1300px)  {
      margin-left: 38%;
      
    }
    @media screen and (max-width: 1200px)  {
      margin-left: 40%;
      
    }

    }
   
   
  }
  .add-destination-button{
    color: #000;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 257.143% */
    border-radius: 4px;
    border: 1px solid #EDEDED;
    background: #FFF;
    width: 201px;
    height: 42px;
    flex-shrink: 0;
    box-shadow: 1px 3px #dfdcdc;
    .plusIcon{
      height: 14px;
      padding-left: 5px;
      padding-right: 5px;
      
    }
  }
  

}
.card-main-container{
  // position: absolute;
  // top:180px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.add-card-main{
  border: 1px solid var(--Border, #EDEDED);
  background: var(--Light, #FFF);
 width: 100%;
  height: 140px;
  display: flex;
  justify-content: space-evenly;
  .state-serach{
    display: flex;
    flex-direction: column;
   
    .select-state{
      margin-top: 24px;
      width: 230px;
      height: 40px;
      border-radius: 6px;
      border: 1.267px solid #C4C4C4;
    }
    .search{
      margin-top: 15px;
      width: 230px;
      height: 40px;
      border-radius: 6px;
      border: 1.267px solid #C4C4C4;

    }
  }
  .description{
    background-color: rgb(230, 230, 230);
    width: 285px;
    border-radius: 6px;
    margin-top: 25px;
    

    
    height:80px;
    p{
      width: 98%;
      text-align: center;
      align-self: stretch;
      color: #000;
      font-family: 'DM Sans', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 233.333% */
      opacity: 0.5;
      height: 15px;
     
    }
    textarea{

      width: 285px;
      height: 65px;
      resize: none;
      border-radius: 6px;
      margin-bottom: 30px;
      border: none;
      border: 1.267px solid #C4C4C4;
      
    }

  }

  .priority{

    width: 90px;
    border-radius: 6px;
    margin-top: 55px;
    background-color: rgb(230, 230, 230);
   

   
    height:40px;
    p{
      width: 100%;
      text-align: center;
      align-self: stretch;
      color: #000;
      height: 15px;
      
      
     
      font-family: 'DM Sans', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 233.333% */
      opacity: 0.5;
     
    }
    input{

      width: 100%;
      height: 25px;
      resize: none;
      border-radius: 4px;
      border: none;
      border: 1.267px solid #C4C4C4;
      
    }
    input[readonly]:focus {
      outline: none;
      
    }

  }

  .edit-image{
    width: 121px;
    height: 121px;
   border-radius: 4px;
    margin-top: 10px;
    position: relative;
    .image{
     width: 121px;
     height: 121px;
      border-radius: 4px;
   
     }
     
     .delete-image-icon{
       position: absolute;
       display: flex;
       top:75%;
       left: 72%;
       background: "rgba(0, 0, 0, 0.6)";
       padding: 2px 3px;
       align-items: center;
       gap: 10px;
       border-radius: 4px;
       border: 1px solid #fff;

       
       font-size: 24px;
       cursor: pointer;
       background-color: #000000;

     }
    
  }
 

  .publish_close{
    // position: relative;
    .closeIcon{
      position: absolute;
      right:4%;
      top:155px;
      cursor: pointer;
      background-color: rgb(240, 238, 238);
      border-radius: 50%;
    }

    .publish-button{
      display: flex;
      width: 120px;
      height: 35px;
      margin-top: 60px;
      padding: 13.944px 48.723px 15.25px 46px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 8px;
      border: 2px solid #FFF;
      background: #2196F3;
      color: white;
  
  
    }

  }
 
}
.edit-card-main{
 border: 1px solid var(--Border, #EDEDED);
  background: var(--Light, #FFF);
  width: 100%;
   height: 140px;
   display: flex;
   justify-content: space-evenly;
   .state-serach{
     display: flex;
     flex-direction: column;
    
    
   
     .state{
       margin-top: 55px;
       width: 230px;
       height: 40px;
       border-radius: 6px;
       border: 1.267px solid #C4C4C4;
       padding-left: 10px;
 
     }
     input[readonly]:focus {
      outline: none;
      
    }
   }
   .description{
     background-color: rgb(230, 230, 230);
     width: 285px;
     border-radius: 6px;
     margin-top: 25px;
 
     
     height:80px;
     p{
       width: 98%;
       text-align: center;
       align-self: stretch;
       color: #000;
      
       font-family: 'DM Sans', sans-serif;
       font-size: 12px;
       font-style: normal;
       font-weight: 400;
       line-height: 28px; /* 233.333% */
       opacity: 0.5;
       height: 15px;
      
     }
  
     textarea{
 
       width: 285px;
       height: 65px;
       resize: none;
       border-radius: 6px;
       margin-bottom: 30px;
       border: 1.267px solid #C4C4C4;
       padding-left: 5px;
       
     }
     textarea[readonly]:focus {
      outline: none;
    
    }
    
 
   }
 
   .priority{
 
     width: 90px;
     border-radius: 6px;
     margin-top: 55px;
     background-color: rgb(230, 230, 230);

 
    
     height:40px;
     p{
       width: 100%;
       text-align: center;
       align-self: stretch;
       color: #000;
       height: 15px;
       
      
       font-family: 'DM Sans', sans-serif;
       font-size: 12px;
       font-style: normal;
       font-weight: 400;
       line-height: 28px; /* 233.333% */
       opacity: 0.5;
      
     }
     input{
 
       width: 100%;
       height: 25px;
       resize: none;
       border-radius: 4px;
       border: 1.267px solid #C4C4C4;
       text-align: center;
       
     }
     input[readonly]:focus {
      outline: none;
      
    }
 
   }
   .edit-image{
     width: 121px;
     height: 121px;
    border-radius: 4px;
     margin-top: 10px;
     position: relative;
     .image{
      width: 121px;
      height: 121px;
       border-radius: 4px;
    
      }
      
      .delete-image-icon{
        position: absolute;
        display: flex;
        top:75%;
        left: 72%;
        background: "rgba(0, 0, 0, 0.6)";
        padding: 2px 3px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #fff;

        
        font-size: 24px;
        cursor: pointer;
        background-color: #000000;

      }
     
   }
.edit_delete{

  width: 120px;
  display: flex;
  justify-content: center;
  .editLogo{
    height: 20px;
    margin-top: 60px;
    cursor: pointer;
  }
  .deleteLogo{
    height: 20px;
    margin-top: 60px;
    margin-left: 20px;
    cursor: pointer;

  }
  .back-button{
    width: 120px;
    height: 35px;
    border-radius: 4px;
    border: 2px solid #e2e0e0;
    background: #FFF;
    margin-top: 60px;
    background-color: rgb(230, 230, 230);

  }
  .update-button{
    width: 120px;
    height: 35px;
   
    border: 1px solid #EDEDED;
   
    margin-top: 60px;
    margin-left: 10px;
    border-radius: 8px;
   
    background: #2196F3;
    color: white;
  }
  .delete-button{
    width: 120px;
    height: 35px;
    border-radius: 6px;
    border: 1px solid #e2e0e0;
    background: #FFF;
    margin-top: 60px;
    background-color: rgb(91, 90, 90);
    color:white;
    margin-left: 10px;

  }

}
 }
 .call-back-main{
 
  .heading-date{
  position: absolute;
  top: 20px;
  display: flex;
  
  .text{
      color: var(--black-high-emphasis, rgba(0, 0, 0, 0.87));
      font-family: 'DM Sans', sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.15px;
      
  }
  .date-filter{
    position: absolute;
    bottom: 40px;
    left:400px;
    width: 400px;
    
  }
  



  }
  .search{
    display: flex;
    padding: 6px 0px 6px 20px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #EDEDED;
    background: #FFF;
    width: 95%;
  }
 }
.CallBack-card{
  display: flex;
width: 95%;
height: 93px;
flex-shrink: 0;
border: 1px solid var(--Border, #EDEDED);
background: var(--Light, #FFF);

margin-top: 20px;
.name-request{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 20px;
 .name{
    color: #000;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
    padding-bottom: 0px;

  }
  .request-date{
    color: #797979;
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
    
  }
  
}
.contact-box{
 
  margin-top: 15px;
  height: 50px;
 
  border-radius: 4px;
  p{
    color: #000;
    text-align: center;
    background-color: rgb(150, 148, 148);
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 233.333% */
    opacity: 0.5;
    margin-bottom: 0px;
    border-radius: 4px;
   
 
  }
  .number{
    color: rgb(14, 13, 13);
    
    padding: 4px 6px;
    font-weight: 500;
    font-size: 14px;
   
    gap: 6px;
  
    border-radius: 4px;
    background: #FDFDFD;
    border: 1px solid #EDEDED;
    width: 150px;
    text-align: center;

  }
  input[readonly]:focus {
    outline: none;
    
  }
}
.email-box{
  
  margin-top: 15px;
  height: 50px;
 
  border-radius: 4px;
  p{
    color: #000;
    text-align: center;
    font-family: DM Sans;
    background-color: rgb(150, 148, 148);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 233.333% */
    opacity: 0.5;
    margin-bottom: 0px;
    border-radius: 4px;
   
 
  }
  .email{
 
    padding: 4px 6px;
   
    gap: 6px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    background: #FDFDFD;
    border: 1px solid #EDEDED;
    color: var(--Accent, #1d8fed);
    width: 250px;
    text-align: center;
    transform: translate3d(0,0,0);
   

  }
  input[readonly]:focus {
    outline: none;
    
  }

}
.location-box{
  margin-left: 5%;
  margin-top: 15px;
  height: 50px;
 
  border-radius: 4px;
  p{
    color: #000;
    text-align: center;
    font-family: DM Sans;
    background-color: rgb(150, 148, 148);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 233.333% */
    opacity: 0.5;
    margin-bottom: 0px;
    border-radius: 4px;
   
 
  }
  .location{
 
    padding: 4px 6px;
   
    gap: 6px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    background: #FDFDFD;
    border: 1px solid #EDEDED;
    color: var(--Accent, #1d8fed);
    width: 250px;
    text-align: center;
    transform: translate3d(0,0,0);
   

  }
  input[readonly]:focus {
    outline: none;
    
  }

}

}

.add-image-button{
  position: relative;
  width: 120px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #EDEDED;
  background: #FFF;
  margin-top: 60px;
  cursor: pointer;
  label{
    span{
      position: absolute;
      left:15px;
      top:6px;
      cursor: pointer;

  }


  }
  


}