.close-request-modal-rt-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
}
.close-request-modal-rt-content{
    background-color: white;
    border-radius: 5px;
    margin: 0px 10px;
    box-shadow: 0px 0px 0px 2px #fff;
}
.label{
    padding: 0px 15px;
    opacity: .4;
}
.close-icon{
    position: absolute;
    right: 5%;
    top: 5%;
}