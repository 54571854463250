// .calender-modal{
//     position: absolute;
//     left: 35%;
//     right: 0;
//     top: 76%;
//     width: 50%;
//     z-index: 2;
//     display: flex;
//     flex-direction: column;
//     padding: 50px;
//     width: 30%;
//     background-color: #FFFFFF;

//     .react-calendar{
//         border: none;
//     }
//     .calender-header{
//         .react-calendar__navigation__prev-button
//         {
//             outline: none !important;
//             border-width: none !important;
//             border: none !important;
//             padding: 10px !important;
//             background-color: #FFFFFF !important;
//             border-radius: 50% !important;
//             font-size: 50px !important;
//         }

//         .react-calendar__navigation__next-button{
//             outline: none !important;
//             border-width: none !important;
//             border: none !important;
//             padding: 10px !important;
//             background-color: #FFFFFF !important;
//             border-radius: 50px !important;
//             font-size: 50px !important;
//         }
//         span{
//             padding: 8px 31px;
//             color: #000000;
//             font-weight: 600;
//         }
//    }
//     .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
//         display: none !important;
//     }
//     .react-calendar__navigation__label {
//         color: #000000;
//         background-color: #F8F8F8 !important;
//         font-weight: 600;

//         &:hover{
//             background-color: #F8F8F8 !important;
//         }
//     }
//     .react-calendar__navigation button {
//         width: 40px;
//     }
//     .react-calendar__month-view__weekdays__weekday > abbr {
//         text-decoration: none !important;
//     }
//     .check-in{
//         flex-direction: column;
//         p{
//             color: #000000;
//         }
//         h5{
//             font-weight: 900;
//         }
//     }
//     .check-out{
//         flex-direction: column;
//         margin-top: 32px;
//         p{
//             color:  #000000;
//         }
//         h5{
//             font-weight: 900;
//             color:  #000000;
//         }
//     }
//     .calender-footer-btn{
//         margin-top: 45px;
//     }
// }

.bnt-container{
    margin: 30px 20px 8px 0px ;
    text-align: end;
    button{
        background-color:#2196F3;
        width: 66px;
        color: #fff;
        border-width: inherit;
        border-radius: 2px;
    }
}
.date-range-date{
    background-color: #f1f1f1;
    padding: 2px 19px;
    border-radius: 20px;
    cursor: pointer;
    
}
.date-rande-active{
    background-color: #747474;
    color: #fff;
    padding: 2px 19px;
    border-radius: 20px;
    cursor: pointer;
}