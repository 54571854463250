.common-more-info-container{
    padding: 35px 15px;
   .common-more-info-heading{
    text-align: left;
    width:95%
   }
   .common-more-info-close-icon{
    position: absolute;
    right: 15px;
    font-size: 15px;
    top:2%
   }

   .common-more-info-booked-on{
    color:#8f8f8f;
   }

   .common-more-info-details{
    label{
        color:#8F8F8F
    }
    .common-more-info-left-contain{
        .common-more-info-image img{
            border-radius:33px;
        }
        .common-more-info-booked-for{
            margin-top: 13px;
            .common-more-info-details-left{
                background-color: #ffff ;
                border-radius: 8px;
                span{
                    color: #2296F3 ;
                    display: block ;
                    padding: 5px ;
                    margin-left: 10px;
                }
                p {
                    padding: 5px;
                    margin-left: 10px;
                }
                p:first-child{
                    color: #8F8F8F;
                    margin-left: 10px;
                }
                .common-more-info-msg{
                    padding: 5px;
                }
            }
        }
    }
    .common-more-info-right-contain{
        .right-details-input{
            padding: 7px;
            .common-more-info-booked-for-details-right{
                background-color: #ffff ;
                border-radius: 8px;
                p {
                    padding: 5px;
                    margin-left: 10px;
                }
                p:first-child{
                    color: #8F8F8F;
                    margin: 0px;
                    margin-left: 10px;
                }

            }
        }
    }

   }
}