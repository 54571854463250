.enquiry-tabs-holder {
  .ant-tabs-tab-btn {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    opacity: 0.4;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000000;
    opacity: 1;
  }

  .ant-tabs-ink-bar {
    background: #2196f3;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 52px;
  }
}

.enquiry-card-holder {
  .d-none {
    display: none !important;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #ededed;
  padding: 14px 20px;
  margin-bottom: 20px;
  .organization-details {
    // margin-right: 100px;
    width: 550px;
    h1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
      margin-bottom: 8px;
    }

    .info {
      p {
        &:nth-child(1) {
          font-size: 12px;
          line-height: 16px;
          color: #797979;
          margin-bottom: 8px;
        }

        &:nth-child(2) {
          font-weight: 500;
          color: #212121;
          margin-bottom: 0px;
        }
      }
    }
  }

  .contact-info {
    width: 200px;
    background-color: #f4f4f4;
    border: 2px solid #f4f4f4;
    border-radius: 7px;
    .title {
      background: #f4f4f4;
      border-radius: 8px 8px 0px 0px;
      p {
        font-size: 12px;
        line-height: 28px;
        color: #000000;
        opacity: 0.5;
        margin-bottom: 0;
        text-align: center;
      }
    }
    .rating-title {
      margin-left: 14px;
      font-size: 14;
      font-weight: 400;
      color: #000000;
      opacity: 0.5;
    }
    .rating-details {
      background: #ffffff;
      border-radius: 4px;
      height: 30px;
      margin: 10px 15px;
      p {
        padding-left: 14px;
        padding-top: 7px;
      }
    }
    .contact-details {
      padding: 6px 12px;
      background: #fdfdfd;
      border-radius: 5px;
      min-height: 50px;
      .email {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #2196f3;
        margin-bottom: 6px;
      }
      .comment {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #212121;
        span {
          color: #2196f3;
          margin-left: 5px;
          cursor: pointer;
        }
      }
      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #212121;
        margin-bottom: 0px;
        hyphens: auto;
      }
    }
  }

  .status-dropdown {
    background: #fbfcfd;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 4px;
    width: 154px;
    height: 32px;
  }
}
.dropdown-filter {
  color: #2196f3 !important;
  text-transform: capitalize !important;
  margin-top: 5px !important;
  text-decoration: underline !important;
  font-size: 14px !important;
  &::after {
    content: "\25bc" !important;
    margin-left: 5px;
    font-size: 10px;
    margin-top: 2px;
  }
}
