.PhoneInput{
    border: 1px solid #333;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    line-height: 1.5;
    font-size: 1rem;
    input{
        border: 0px solid #000 !important;
        
    }
}
.PhoneInput--focus{
    input:focus{

        outline: none !important;
    }
}

.packageDetails-holder {
    background: #FFFFFF;
    border-radius: 8px;
    margin-left: 10px;
    padding: 17px 16px;

    .form-options {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-weight: normal;
            font-size: 20px;
            color: #000000;
            opacity: 0.4;
        }
    }
    .pack-category{
        margin-top: 10px;
       
        .pack-category-buttons{
            display: flex; 
            .active{
                background: #2196F3;
                width: auto;
                color: #fff;
            }
            div{
                text-align: center;
                padding: 10px;
                flex: 0.5;
                cursor: pointer;
                background-color: #EDEDED;
            }
            :first-child{
                margin-right: 10px;
            }

        }
        
    }

    .section-dropdown {
        width: 154px;
        height: 27px;
        background: #FBFCFD;
        border: 1px solid #C4C4C4;
        border-radius: 4px;
        font-size: 12px;
        color: #000000;
        display: flex;
        align-items: center;

        span {
            font-size: 12px;
            text-transform: none;
        }
    }

    .menu-option {
        background: #FBFCFD;
        border: 1px solid #C4C4C4;
        width: 50px;
        height: 27px;
        margin-left: 8px;
        border-radius: 4px;

        .MuiButton-label {
            transform: rotate(90deg);
        }
    }

    .form-custom {
        background: #F5F5F5;
        border-radius: 4px;
        padding: 14px 16px;
        margin-top: 16px;
        label {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #000000;
            opacity: 0.4;
            margin-bottom: 12px;
        }
    }
}

.toggle-container{
    background: #F5F5F5;
    border-radius: 4px;
    padding: 14px 16px;
    margin-top: 16px;
    label {
        margin: 5px 10px;
    }
}