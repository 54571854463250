.date-range_container{
    .date-range-content{
        margin-left: 30px;
        background-color: white;
        border-radius: 8px;
        padding: 10px 0px;
        width: 170px;
        div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        button{
            border-width: 0px;
            border: 1px solid #ffff;
            width: 65%;
            border-radius: 3px;
            padding: 5px;
        }
        span{
            color: #2296F3;
        }
    }
    .date-range{
        .link{
            width: 140px !important;
            margin-left: 10px !important;
            background-color: #DCDCDC !important;
        }
        .form-group{
            left: 100% !important
        }
         .DayPicker-Day--today{
            background-color: black !important;
            color: white !important;
         }
    }
    .schedule-container{
        background-color: white;
        border-radius: 8px;
        .schedule-containet{
            padding: 10px;
            hr{
                width: 90%;
                color: black;
            }
            span{
                display: block;
                margin-bottom: 10px
            }
            p{
                display: inline-block;
            }
            .cross-icon{
                font-size: 8px;
                display: inline-block;
                cursor: pointer;
                margin-right: 10px;
                position: absolute;
                right: 35px
            }
        }
        .btn-container{
            display: flex;
            padding-bottom: 10px;
            button{
                border-width: 0px;
                border-radius: 5px;
                padding: 10px;
            }
            .delete-btn{
                width: 35%;
                margin-left: 10px
            }
            .save-btn{
                margin-left: 25px;
                width: 95px;
                background-color: #2296F3;
                color: white;
            }

        }
    }
   .DayPicker{
        background-color: #fff;
        margin-top: 15px;
        border-radius: 5px
    }
}
.date-range-btn{
    height: 13%;
    width: 12%;
    background-color: #F1F1F1;
    margin-left: 23px;
    border-radius: 5px; 
    border: solid 1px #d1cccc;
    padding: 6px
}
.date-range-active{
    height: 13%;
    width: 12%;
    padding: 6px;
    background-color: #fff;
    margin-left: 23px;
    border-radius: 5px; 
    border: solid 2px #2296F3
}