.bookings--holder {
    .cards-list {
        h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
        }
    }

    .active-card {
        border: 2px solid #212121;
        box-sizing: border-box;
        border-radius: 4px;
    }

    .overview-holder {
        background: #FFFFFF;
        margin-top: 10px;
        margin-left: 10px;
        border: 1px solid #EDEDED;

        .data-holder {
            padding: 16px;
            display: flex;
            border-bottom: 4px solid #ccc;

            .bookings {
                background: #F5F5F5;
                border-radius: 8px;
                width: 40%;
                padding: 14px 16px;
                margin-right: 10px;

                h5 {
                    color: rgba(0, 0, 0, 0.87);
                    margin-bottom: 6px;
                    font-size: 18px;
                    font-weight: 700;
                }

                .info {
                    p {
                        margin-bottom: 6px;

                        &:nth-child(2) {
                            color: #2196F3;
                        }
                    }
                }
            }

            .payment-details {
                background: #F5F5F5;
                border-radius: 8px;
                width: 60%;
                padding: 14px 16px;

                .payment-status {
                    display: flex;

                    p {
                        font-weight: 500;

                        &:nth-child(1) {
                            color: #CD4141;
                            margin-right: 10px;
                        }

                        // &:nth-child(2) {
                        //     color: #CD4141;
                        // }
                    }
                }
            }
        }

        .payments-list-holder {
            height: 100vh;
            overflow: auto;

            .all-payment-details {
                padding: 20px;
                border-bottom: 4px solid #ccc;

                .user-details {
                    display: flex;
                    justify-content: space-between;

                    .user {
                        h6 {
                            font-size: 15px;
                            margin-bottom: 8px;
                            font-weight: 500;
                        }

                        p {
                            color: #797979;
                            font-size: 12px;
                        }
                    }

                    .amount {
                        h6 {
                            font-size: 15px;
                            margin-bottom: 8px;
                            font-weight: 500;
                            text-align: right;
                        }

                        p {
                            font-size: 12px;
                            font-weight: 500;
                            text-align: right;
                        }
                    }
                }


            }
        }
    }
}

.contact-details-holder {
    display: flex;
    justify-content: space-between;

    .contact-info {
        width: 200px;
        background-color: #F4F4F4;
        border: 2px solid #F4F4F4;
        border-radius: 7px;

        .title {
            background: #F4F4F4;
            border-radius: 8px 8px 0px 0px;

            p {
                font-size: 12px;
                line-height: 28px;
                color: #000000;
                opacity: 0.5;
                margin-bottom: 0;
                text-align: center;
            }
        }

        .contact-details {
            padding: 6px 12px;
            background: #FDFDFD;
            border-radius: 5px;
            height: 50px;

            .email {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #2196F3;
                margin-bottom: 6px;
            }

            p {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #212121;
                margin-bottom: 0px;
                hyphens: auto;
            }
        }
    }

    .payment-status {
        p {
            font-size: 12px;
            width: 154px;
            /* height: 30px; */
            text-align: center;
            margin-bottom: 14px;
            padding: 6px;
            border-radius: 4px;
        }

        .status-dropdown {
            background: #FBFCFD;
            border: 1px solid #C4C4C4;
            box-sizing: border-box;
            border-radius: 4px;
            width: 154px;
            height: 32px;
        }
    }
}

.transaction-modal {}

.tranaction-title {
    position: relative;
    margin: -10px -10px 12px;
    height: 62px;
    background: rgba(0, 0, 0, 0.1);

    p {
        color: rgba(0, 0, 0, 0.5);
    }

    .close-icon {
        position: absolute;
        top: 19px;
        right: 18px;
        cursor: pointer;
    }
}

.modal-details {
    margin: 10px;

    p {
        color: #212121;
        margin-bottom: 8px;
    }
}

.confirmed--btn {
    font-weight: 500;
    font-size: 12px;
    color: #2196F3;
    border: 2px solid #2196F3;
    box-sizing: border-box;
    border-radius: 4px;
}

.cancelled--btn {
    font-weight: 500;
    font-size: 12px;
    color: #DA7519;
    border: 2px solid #DA7519;
    box-sizing: border-box;
    border-radius: 4px;
}

// .cancel-booking-modal {
// }
.close-cancel-icon {
    position: absolute !important;
    right: 18px !important;
    top: 15px !important;
    cursor: pointer;
}

.message-box {
    padding: 12px 50px;
    /* text-align: center; */
    margin: 0 auto;
    background: #FFFFFF;
    border: 1px solid #EDEDED;

    h5 {
        color: #2196F3;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 28px;
    }

    .user-info {
        text-align: center;

        h4 {
            margin-top: 22px;
            font-size: 15px;
            margin-bottom: 8px;
        }

        p {
            color: #797979;
            font-size: 12px;
        }
    }
}

.cta-holder {
    text-align: center;
    margin: 32px 0px 6px;

    .dont-cancel {
        background: #EDEDED;
        border-radius: 4px;
        color: #212121;
        font-size: 14px;
        font-weight: bold;
        margin-right: 20px;
        width: 136px;
        height: 48px;

    }

    .cancel-btn {
        background: #212121;
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        width: 185px;
        height: 48px;

    }
}

// 

.confirmPayment-holder {
    position: fixed;
    top: 50%;
    width: 480px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 40px;
}

.input--wrapper {
    text-align: center;
    background: #EDEDED;
    border-radius: 8px;
    margin: 20px 40px 0px;
    padding: 10px;

    label {
        color: #000000;
        opacity: 0.6;
        font-size: 14px;
    }

    .amountInput-holder {
        background: #FFFFFF;
        display: flex;
        width: 260px;
        justify-content: center;
        margin: 0 auto;
        align-items: center;
        border-radius: 4px;

        p {
            margin-bottom: 0;
            padding: 14px;
            font-size: 12px;
            color: #797979;
            border-right: 1px solid #F4F4F4;
        }

        input {
            display: block;
            margin: 0 auto;
            background: #FFFFFF;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            box-sizing: border-box;
            border: transparent;
            height: 43px;
            width: 100%;
            padding: 0px 10px;
        }

    }
}

.errorMsg {
    color: #CD4141;
    margin-top: 5px;
}
.Input{
    float: left;
}
.firstInput{
    width: 76%;
}
.clear{
    clear: both;
}
.secondInput{
    margin-left: 15px;
    margin-top: 8px;
}
.selectTag {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* Remove default arrow */
    background-image: url("../../../images/arrowDown.svg");   /* Add custom arrow */
    background-repeat: no-repeat;
    background-position:95% center;
    border-color: #EDEDED;
    height: 42px;
    width: 150px;
    border-radius: 5px;
    padding: 0px 10px 0px;
    cursor: pointer;
}
 .search-list:focus{
   box-shadow: 0 0 5px #007bff !important;
 }

 .draggable-icon {
    position: relative;
    z-index: 999;
    border-radius: 4px;
    cursor: pointer;
    // background-color: rgba(0, 0, 0, 0.6);
    // left: 9px;
    // top: 26px;
    // border: 1px solid white;
    // color: white;
    // padding: 4px 8px;
 }