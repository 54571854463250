.MuiDrawer-paperAnchorDockedLeft {
    background-color: #E8E8E8 !important;
}

.admin-dropdown {
    background: transparent;
    border: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.8);

    &:hover {
        background: transparent;
        color: rgba(0, 0, 0, 0.8);
    }

    &:focus {
        box-shadow: none !important;
        background-color: transparent !important;
        color: rgba(0, 0, 0, 0.8) !important;
        border-color: transparent !important;
    }
}

.menu-list {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #212121;
    opacity: 0.6;
}
.sub-menuList {
    font-weight: 500;
font-size: 18px;
line-height: 23px;
color: #212121;
opacity: 0.6;
}
.custom-icon {
    margin: 0;
    font-weight: 600;
    /* color: #000; */
    font-size: 22px;
}
.MuiCollapse-container {
    background-color: #fff;
}

.MuiAppBar-root {
    box-shadow: none !important;
    background-color: #E8E8E8 !important;
}
.MuiIconButton-label {
    svg {
        color: #212121;
    }
}

.MuiLinearProgress-barColorPrimary {
    background-color: #2196F3 !important;
}
.MuiLinearProgress-colorPrimary {
    background-color: #daedfc !important;
}