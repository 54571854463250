.webstories {
  .heading {
    color: var(--black-high-emphasis, rgba(0, 0, 0, 0.87));

    /* Page title */
    font-size: 32px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.15px;
  }
  .mainContainer {
    display: flex;
  }
  .story-title-box {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 80px;
    .story-title-label {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      width: 90%;
      background-color: #f5f5f5;
      p {
        margin-top: 10px;
        width: 100%;
        margin-left: 10px;
      }

      .story-title-input {
        display: flex;
        justify-content: space-between;

        border-color: black;
        width: 100%;
        height: 55px;
        background-color: #f5f5f5;
        input {
          margin:1%;
          font-size: 14px;
          font-family: DM Sans;
        
        }
        img {
          position: absolute;

          width: 15px;
          left: 90%;
          margin-top:20px;
        
        }
      }
    }
  }
 
  .story-card-container {
    display: flex;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: center;
    .story-cards {
      width: 90%;
      display: flex;
      flex-wrap: wrap;

      .story-card-add {
        width: 199px;
        height: 350px;
        margin: 10px;
        border-radius: 12px;

        background: #f4f4f4;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed black;
       

      }

      .story-card-image {
        width: 199px;
        height: 350px;
        margin: 10px;
        border-radius: 12px;
        position: relative;
        display: inline-block;
        .thumbnail{
    color: white;
    position: absolute;
    left: 40%;
    text-align: right;
    width: 60%;
    padding: 4px;
    border-radius: 4px;
        }
        .story-card-image-main {
          border-radius: 12px;
          width: 199px;
          height: 350px;
        }
        .story-card-edit {
          display: flex;
          position: absolute;
          padding: 8px 10px;
          align-items: center;
          gap: 10px;
          border-radius: 10px;
          border: 1px solid #fff;
          background: rgba(0, 0, 0, 0.6);
          top: 88%;
          left: 55%;
          font-size: 24px;
          cursor: pointer;
        }
        .story-card-delete {
            display: flex;
            position: absolute;
            padding: 8px 10px;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            border: 1px solid #fff;
            background: rgba(0, 0, 0, 0.6);
            top: 88%;
            left: 75%;
            font-size: 24px;
            cursor: pointer;
        }
      }
    }
  }
  .story-card-modal{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    top: 30%;
    left: 10%;
    height: auto;
    width: 80%;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 40px 20px;;

    

    .story-modal-add-image{
       
        height: 330px;
        width:40%;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
       
    
        .uploded-image{
          height: 350px;
          width: 102%;
          border-radius: 12px;
          
        }
        .uploded-image-delete{
          position:absolute;
          top: 90%;
          left:80%;
          cursor: pointer;
          width: 40px;
          height: 30px;
          background: rgba(0, 0, 0, 0.6);
         border-style: solid;
          border-color: white;
         border-radius: 8px;
          


        }
        .story-modal-image-guidelines{
            display: flex;
            height: 30%;
            flex-direction: column;
            margin-top: 30%;
            .story-modal-upload-img{
               
                margin-left: 40%;
                label{
                    img{
                        height: 50px;
                        width:50px;
                        cursor: pointer;

                    }
                   
                }
            }
            .story-modal-upload-img:hover{
                cursor: pointer;
            }
            p{
                color: #000;
                text-align: center;
                height: 10%;

            font-size: 10.566px;
            font-family: DM Sans;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            }
            .story-modal-image-recomadation{
                p{
                    color: #000;
                    
    
                font-size: 9px;
                font-family: DM Sans;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                }
                ul{
                    margin: 25px;
                    li{
                        color: #000;
                      
                    font-size: 7.5px;
                    font-family: DM Sans;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    }
                }

            }
           
            
        }


    }
    .story-modal-image-details{
       
        height: 350px;
        width:55%;
       
        .story-modal-image-title{
            background-color: #F5F5F5;
            width: 100%;
            // height: 30%;
            border-radius: 12px;

            .story-title-box {
                display: flex;
                width: 100%;
                justify-content: center;
                margin-top: 0px;
                .story-title-label {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  background-color: #f5f5f5;
                  p {
                    margin-top: 10px;
                    width: 100%;
                    margin-left: 10px;
                  }
            
                  .story-title-input {
                    display: flex;
                    justify-content: space-between;
            
                    border-color: black;
                    width: 100%;
                    height: 45px;
                    background-color: #f5f5f5;
                    input {
                      margin:1%;
                      font-size: 14px;
                      font-family: DM Sans;
                    
                    }
                    img {
                      position: absolute;
            
                      width: 15px;
                      left: 90%;
                      margin-top:15px;
                    
                    }
                  }
                }
              }
        }
        .story-modal-image-description{
            background-color: #F5F5F5;
            width: 100%;
            height: auto;
            margin-top: 5%;
            border-radius: 12px;

            .story-title-box {
                display: flex;
                width: 100%;
                justify-content: center;
                margin-top: 0px;
                .story-title-label {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  background-color: #f5f5f5;
                  p {
                    margin-top: 10px;
                    width: 100%;
                    margin-left: 10px;
                  }
            
                  .story-title-input {
                    display: flex;
                    justify-content: space-between;
            
                    border-color: black;
                    width: 100%;
                    height: 55px;
                    background-color: #f5f5f5;
                    textarea {
                      margin:1%;
                      font-size: 14px;
                      font-family: DM Sans;
                      height: 85px;
                    
                    }
                    img {
                      position: absolute;
            
                      width: 15px;
                      left: 90%;
                      margin-top:15px;
                    
                    }
                  }
                }
              }
        }
        .story-modal-image-animation{
            background-color: #F5F5F5;
            width: 100%;
            height: 20%;
            margin-top: 18%;
            border-radius: 12px;
            border: 1px black;

        }
      

    }
    .story-card-modal-button{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 50px;
    }
  }

  
}
