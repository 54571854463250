

.my-family-first-content{
    background-color: #fff;
    border-radius: 8px;
}
.my-family-first-close.icon{
    position: absolute;
    right: 55px;
}
.MuiDialog-paperScrollPaper {
    width:100%;
    padding: 10px;
}
.MuiPaper-root{
    background-color:#F5F5F5!important ;
}
.my-family-more-info{
    .my-family-first-label{
        margin-top: 13px;
        label{
            color: #AFAFAF;
        }
    }
    .my-family-first-content{
        margin-bottom: 20px;
        padding: 5px;
        p{
            margin-bottom: 2px;
            hyphens: auto;
        }
        div{
            display: flex;
            flex-direction: column;
            margin-left: 5px;
            margin-bottom: 5px;
        }
    }
}