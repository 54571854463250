
.more-info-modal-wraper{
    
    .more-info-card{
        background-color: #eee;
        // box-shadow: 0px 0px 0px 2px #F5F5F5;
        padding: 35px 15px;
        width: 700px;
    }
    .info{
        z-index: 1400;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        position: absolute;
        width: 700px;
    }
    .more-info{
        label{
            color:#8F8F8F
        }
     }
}
.info-content{
    margin: 5px;
}
.MuiDialog-paperWidthSm {
    max-width: 650px ;
}
.info-book-for{
    background-color: #ffff ;
    border-radius: 8px;
    span{
        color: #2296F3 ;
        display: block ;
        padding: 5px ;
    }
    p {
        padding: 5px;
    }
    p:first-child{
        color: #8F8F8F;
        margin: 0px;
    }
    .info-msg {
        padding: 5px;
    }
}


body.modal-close {
    overflow: visible !important;
    position: absolute !important;   
}

