.featured_package {
    background: #FBFCFD;
                    position: static;
                    border: 1px solid #C4C4C4;
                    box-sizing: border-box;
                    border-radius: 4px,
;                    height: 27px;
                    width: 154px;
                    right: 335px;
                    top: 0px;
                    font-weight: 400;
                    font-family: DM Sans;
                    font-size: 12px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin: 0px 8px;
}
// .featured_package-div {
//     color: #000000;
//     width: 111px;
//     height: 16px;
//     left: 13px;
//     line-height: 16px;
//     padding-left: 7px;
//     padding-right: 0px;
//     display: flex;
//     align-items: center;
//     font-weight: 400;
//     font-family: DM Sans;
//     font-size: 12px;
// }