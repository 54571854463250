.employeeListHover {
    display: flex;
    flex-direction: column;
    // transition: transform 350ms;
    // &:hover {
    //     transform: translateX(+50px);
    //     .showHide{
    //         display: block;
    //     }
    //     // transitionDelay: '5s'
    // }
}
.parent:hover{
    .employeeListHover{
        transition: transform 350ms;
        transform: translateX(+50px);
    }
    &:hover {
        .showHide{
            display: block;
        }
        // transitionDelay: '5s'
    }
}
.showHide {
    // opacity: 0,
    // '&:hover': {
    //     opacity: 0
    // }
    display: none;
}

.our-team .profile-holder:hover .info .social-media {
    display: block;
    }