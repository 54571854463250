.search-list {
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    margin-right: 5px !important;
    height: 42px;
    font-size: 14px;
    color: rgba(0,0,0,0.5);
    margin: 3px 5px;
    &:focus {
        box-shadow: none !important;
        border: 1px solid #EDEDED;
        // border: none !important;
    }
}

.card {
    font-size: 10px !important;
}

.add-new {
    white-space: nowrap;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    width: 132px;
    height: 42px;
    margin-left: 10px;
}

.address-holder {
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    padding: 12px;
    margin-left: 20px;
    p {
        margin-bottom: 0;
    }
}
.nameChar{
    p {
      margin-bottom: 0px;
      font-size: 17px;
      font-weight: 700;
    }
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f8f8;
    border-radius: 100%;
    border: 2px solid #600abd;
  }

.location-title {
    .MuiTypography-root {
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
            cursor: pointer;
        }
    }
}

.MuiDialogContent-dividers {
    border-top: transparent !important;
    border-bottom: transparent !important;
}

.location-details {
    position: relative;
    height: 100%;
    .map-btn {
        position: absolute;
        bottom: 0;
        right: 0;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        background: #2196F3;
        border-radius: 2px;
        font-weight: 700;
        font-size: 14px;
        color: #FFFFFF;
        padding: 10px 30px;
    }
}

.location-field {
    position: relative;
    .pin-icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    .form-control:disabled, .form-control[readonly] {
        background-color: #FFFFFF !important;
    }
}

.no-location {
   margin-bottom: 0px;
}

.overflow--scroll::-webkit-scrollbar{
  display: none;
}