
.opening-soon-calander-container{
    .rmdp-arrow-container{
    border-radius: 0px;
    background-color: #fff;
    }
    .rmdp-header {
        background-color: #F8F8F8;
    }
    .rmdp-week-day{
        color: black;
        opacity: .4;
    }
    .rmdp-day.rmdp-today span {
        background-color: black;
        color: white;
        border-radius: 4px;
    }
    .rmdp-shadow {
    box-shadow: none!important;
    }
    .rmdp-wrapper{
        width: auto;
        background-color: #F8F8F8;
    }
    .rmdp-calendar{
        background-color: #fff;
        padding: 15px;
        width:100%;
    }
    .rmdp-day-picker{
        display: block !important;
    }
}