.admin-holder {
    position: relative;
    height: 100vh;

    .logo-holder {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img,
        p {
            margin-bottom: 42px;
        }
        p {
            font-weight: 500;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.87);
        }
        h1 {
            font-weight: 700;
            font-size: 32px;
            line-height: 30px;
            letter-spacing: 0.15px;
            color: rgba(0, 0, 0, 0.87);

        }
    }
}

.login-holder {
    background: #F4F4F4;
    height: 100vh;
    position: relative;

    .form-holder {
        width: calc(100% - 50%);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #FFFFFF;
        border: 1px solid #CED4DA;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 44px 22px;

        @media (max-width:767px) {
            width: 90%;
        }
        label {
            font-size: 12px;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.87);
            margin-bottom: 6px;
        }
    
        input {
            background: #F4F4F4;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.87);
            height: 41px;
            border: none;
            &:focus {
                border-color: transparent;
                box-shadow: none;
                background: #F4F4F4;
            }
        }
        button {
          margin-top: 34px;
          font-weight: 700;
font-size: 14px;
line-height: 24px;
letter-spacing: 0.5px;
color: #FFFFFF;
        }
    }

}